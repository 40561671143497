<template>
  <div class="mt-n3">
    <checkbox
      :fieldAttributes="{ ...fieldAttributes, 'hide-details': true }"
      type="select"
      :fieldAttrInput="fieldAttributes.fieldAttrInput"
      v-model="localValue"
      :result="result"
    ></checkbox>
  </div>
</template>
<script>
import checkbox from "@/commonComponents/checkbox.vue";
export default {
  components: { checkbox },
  props: ["result", "fieldAttributes", "value"],
  computed: {
    localValue: {
      get() {
        let sentValue = this.value;
        if (sentValue == "2") {
          sentValue = 1;
        }
        return sentValue;
      },
      set(newValue) {
        if (newValue == "1") {
          newValue = "2";
        }
        this.$emit("input", newValue);
      },
    },
  },
};
</script>